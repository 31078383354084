import makeRequest, { makeParams } from "../api/makeRequest";
import { RequestMethods } from "../api/requestMethode";
import url from "../api/urls";

export class ClientService {
    static async getAllShifts() {
        return await makeRequest(url.getAllShifts, RequestMethods.GET);
    }

    static async getCountInfo(shiftId: any) {
        return await makeRequest(url.stats.getCountInfo + "/" + shiftId, RequestMethods.GET);
    }


    static async getVerificationData(shiftId: any) {
        return await makeRequest(url.client.getVerificationData + "/" + shiftId, RequestMethods.GET);
    }

    static async getUniqueCity(shiftId: any) {
        return await makeRequest(url.client.getUniqueCity + "/" + shiftId, RequestMethods.GET);
    }
    static async getUniqueCenter(shiftId: any) {
        return await makeRequest(url.client.getUniqueCenter + "/" + shiftId, RequestMethods.GET);
    }
    static async getUniqueZone(shiftId: any) {
        return await makeRequest(url.client.getUniquesZone + "/" + shiftId, RequestMethods.GET);
    }
    static async getUniqueState(shiftId: any) {
        return await makeRequest(url.client.getUniqueState + "/" + shiftId, RequestMethods.GET);
    }

    static async getUsers(shiftId: any, pageSize: any, pageNumber: any, payload: any) {
        const params = makeParams([{ index: "pageNumber", value: pageNumber }, { index: "pageSize", value: pageSize }]);
        return await makeRequest(url.client.getUsers + "/" + shiftId + params, RequestMethods.POST, payload)
    }

    static async downloadVerifiedZip(shiftId: any) {
        return await makeRequest(url.client.downloadVerifiedZip + "/" + shiftId, RequestMethods.GET);
    }

    static async searchStudentByRollNo(shiftId: any ,rollNo: any) {
        const params = makeParams([{ index: "search", value: rollNo }]);
        return await makeRequest(url.client.searchByRollNo + "/" + shiftId + params, RequestMethods.GET);
    }

    static async getDatabyUserId(shiftId: any, userId: any) {
        return await makeRequest(url.client.getDatabyUserId + "/" + shiftId + "/" + userId, RequestMethods.GET)
    }

}