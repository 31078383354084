const url = {
    login: "/client/clientLogin",
    getAllShifts: "/client/getAllExamShift",


    stats: {
        getCountInfo: "/client/countInfo"
    },

    client: {
        getVerificationData: "/client/getVerification",
        getUniqueCity: "/client/uniqueCities",
        getUniqueState: "/client/uniqueStates",
        getUniquesZone: "/client/uniqueZones",
        getUniqueCenter: "/client/uniqueCenters",
        getUsers: "/client/getUsers",
        downloadVerifiedZip: "/client/verifiedZip",
        searchByRollNo: "/client/user/search",
        getDatabyUserId: "/client/getDatabyUserId"
    }



};

export default url;
