import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faBullseye, faFilter, faSearch, faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";
import CsrIndex from "../../View/Csr/Csr.index";
import FilterIndex from "../../View/Filter/Filter.index";
import IRouter from "../Interface/IRouter";
import SearchIndex from "../../View/Search/Search.index";




const mainRoutes: IRouter[] = [
    {
        path: "center-report",
        navbarShow: true,
        element: CsrIndex,
        name: "Center Report",
        icon: faSquarePollVertical
    },
    {
        path: "center-data",
        navbarShow: true,
        element: FilterIndex,
        name: "Center Data",
        icon: faFilter
    },
    {
        path: "search",
        navbarShow: true,
        element: SearchIndex,
        name: "Search Student",
        icon: faSearch
    },
]


export default mainRoutes;